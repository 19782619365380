import React from "react";
import Title from "../home/Title";
import AboutMe from "./AboutMe";
import FunFact from "./FunFact";
import MyServices from "./MyServices";

const About = () => {
  return (
    <section id="about" className="w-full">
      <Title title="درباره" subTitle="ما" />
      <AboutMe />
      <Title title="سرویس" subTitle="ها" />
      <MyServices />
      <Title title="خدمات" subTitle="ما" />
      <FunFact />
    </section>
  );
};

export default About;
