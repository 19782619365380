import React, { useState } from "react";
import Title from "../home/Title";
import { blogImgOne, blogImgTwo, blogImgThree } from "../../assets";
import BlogCard from "./BlogCard";

const Blog = () => {
  const [selectedPost, setSelectedPost] = useState(null);
  const [showRecentPosts, setShowRecentPosts] = useState(true);

  // Define an array of blog posts
  const blogPosts = [
    {
      image: blogImgOne,
      title: "آموزشی",
      subTitle: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
      category: "بیشتر بخوانید",
    },
    {
      image: blogImgTwo,
      title: "آموزشی",
      subTitle: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
      category: "بیشتر بخوانید",
    },
    {
      image: blogImgThree,
      title: "آموزشی",
      subTitle: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
      category: "بیشتر بخوانید",
    },
    // Add other blog posts here
  ];

  // Define an array of blog post content
  const blogPostContent = [
    {
      title: "آموزش خداوند",
      content:
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    },
    {
      title: "آموزش خداوddند",
      content: "محتوای مقاله درباره آموزش خداوند است.",
    },
    {
      title: "آموزddddddsadsaش خداوند",
      content: "محتوای مقاله درباره آموزش خداوند است.",
    },
    // Add content for other blog posts here
  ];

  // Function to handle blog card click
  const handleBlogCardClick = (index) => {
    setSelectedPost(index);
    setShowRecentPosts(false);
  };

  // Function to go back to recent posts
  const handleBackToPostsClick = () => {
    setSelectedPost(null);
    setShowRecentPosts(true);
  };

  return (
    <div id="blog">
      {showRecentPosts && <Title title="پست" subTitle="های اخیر" />}

      {/* Render selected blog post content */}
      {selectedPost !== null ? (
        <div className="p-6 shadow flex flex-col">
          <h1 className="text-2xl font-bold text-designColor border-b-[1px] border-b-zinc-800 pb-4 mb-4">
            {blogPostContent[selectedPost].title}
          </h1>
          <img
            className=""
            src={blogPosts[selectedPost].image}
            alt={blogPostContent[selectedPost].title}
          />
          <p className="text-lg text-textColor leading-loose text-justify">
            {blogPostContent[selectedPost].content}
          </p>
          {/* Add more content here */}
          <button
            onClick={handleBackToPostsClick}
            className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            با زگشت به پست ها
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lgl:gap-10">
          {blogPosts.map((post, index) => (
            <div className="px-6" key={index}>
              <BlogCard
                image={post.image}
                title={post.title}
                subTitle={post.subTitle}
                category={post.category}
                onClick={() => handleBlogCardClick(index)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blog;
