import React, { useState } from "react";
import axios from "axios";
import { FiSend } from "react-icons/fi";
import Title from "../home/Title";

const Contact = () => {
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  // ================= Error Messages Start here =================
  const [errClientName, setErrClientName] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errMessages, setErrMessage] = useState(false);
  // ================= Error Messages End here ===================
  const [seuccessMsg, setSuccessMsg] = useState("");
  // ================= Email Validation Start here ===============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ================= Email Validation End here =================

  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName(false);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail(false);
  };
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessage(false);
  };

  const handleSend = (e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName(true);
    }
    if (!email) {
      setErrEmail(true);
    } else {
      if (!EmailValidation(email)) {
        setErrEmail(true);
      }
    }
    if (!messages) {
      setErrMessage(true);
    }
    if (clientName && email && EmailValidation(email) && messages) {
      axios.post("https://getform.io/f/b99edf0f-52e1-4500-8916-406e7047c0c6", {
        name: clientName,
        email: email,
        message: messages,
      });
      setSuccessMsg(
        `
        سلام دوست عزیز، پیام شما با موفقیت ثبت شد. کارشناسان ما بزودی با شما تماس خواهند گرفت. لطفا صبور باشید. باتشکر - جاویدان
        `
      );
      setClientName("");
      setEmail("");
      setMessages("");
    }
  };
  return (
    <div id="contact" className="w-full">
      <Title title="تماس" subTitle="با ما" />
      <div className="p-6 w-full flex flex-col md:flex-row justify-between gap-4 md:gap-10 lgl:gap-5">
        <div className="w-full lgl:w-1/2">
          <p className="flex flex-row gap-6 justify-between w-full text-lg text-[#ccc] py-4 border-b-[1px] border-b-zinc-800">
            <span className="bg-designColor text-gray-700 text-sm font-titleFont font-medium px-2 rounded-md flex items-center justify-center">
              آدرس
            </span>
            تهران، ایران
          </p>
          <p className="flex flex-row justify-between w-full text-lg text-[#ccc] py-4 border-b-[1px] border-b-zinc-800">
            <span className="bg-designColor text-gray-700 text-sm font-titleFont font-medium px-2 rounded-md flex items-center justify-center">
              شماره تماس اول
            </span>
            ۰۹۱۲۰۰۵۲۸۵۴
          </p>
        </div>
        <div className="w-full lgl:w-1/2">
          <p className="flex flex-row justify-between lgl:gap-6 w-full text-lg text-[#ccc] py-4 border-b-[1px] border-b-zinc-800">
            <span className="bg-designColor text-gray-700 text-sm font-titleFont font-medium px-2 rounded-md flex items-center justify-center">
              ایمیل
            </span>
            Info@TheJavidan.ir
          </p>
          <p className="flex flex-row justify-between w-full text-lg text-[#ccc] py-4 border-b-[1px] border-b-zinc-800">
            <span className="bg-designColor text-gray-700 text-sm font-titleFont font-medium px-2 rounded-md flex items-center justify-center">
              شماره تماس دوم
            </span>
            ۰۹۳۰۳۰۰۸۴۰۳
          </p>
        </div>
      </div>
      <div className="w-full mt-10">
        <Title title="ارسال" subTitle="پیام" />
        <p className="flex flex-row justify-between w-full text-sm text-[#ccc] px-4 py-4 border-b-zinc-800 text-right">
          جهت ثبت دیدگاه، انتقادات و پیشنهادات و سوالات خود میتوانید از طریق فرم
          زیر با کارشناسان ما در ارتباط باشید
        </p>
        {seuccessMsg ? (
          <p className="text-center text-base font-titleFont p-20 text-designColor">
            {seuccessMsg}
          </p>
        ) : (
          <form
            id="form"
            action="https://getform.io/f/b99edf0f-52e1-4500-8916-406e7047c0c6"
            method="POST"
            className="p-6 flex flex-col gap-6"
          >
            <div className="w-full flex flex-col lgl:flex-row gap-4 lgl:gap-10 justify-between">
              <input
                onChange={handleName}
                value={clientName}
                className={`${
                  errClientName
                    ? "border-red-600 focus-visible:border-red-600"
                    : "border-zinc-600 focus-visible:border-designColor"
                } w-full bg-transparent border-2 px-4 py-2 text-base text-gray-200 outline-none duration-300 text-right rounded-r-lg`}
                type="text"
                placeholder="نام شما"
              />
              <input
                onChange={handleEmail}
                value={email}
                className={`${
                  errEmail
                    ? "border-red-600 focus-visible:border-red-600"
                    : "border-zinc-600 focus-visible:border-designColor"
                } w-full bg-transparent border-2 px-4 py-2 text-base text-gray-200 outline-none duration-300 text-right rounded-l-lg`}
                type="email"
                placeholder="آدرس ایمیل"
              />
            </div>
            <textarea
              onChange={handleMessages}
              value={messages}
              className={`${
                errMessages
                  ? "border-red-600 focus-visible:border-red-600"
                  : "border-zinc-600 focus-visible:border-designColor"
              } w-full bg-transparent border-2 px-4 py-2 text-base text-gray-200 outline-none duration-300 resize-none text-right rounded-md`}
              placeholder="پیام یا توضیحات شما"
              rows="4"
            ></textarea>
            <button
              onClick={handleSend}
              className="text-base text-bodyColor lgl:px-44 xs:px-16 py-2 bg-designColor rounded-md flex self-center text-right shadow-[0_0px_17px_-7px_rgba(0,0,0,0.5)] items-center gap-1 text-gray-200 hover:shadow-designColor duration-200"
            >
              ارسال{" "}
              <span className="mt-1 text-bodyColor">
                <FiSend />
              </span>
            </button>
            <span className="mt-10 lgl:hidden"></span>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;
