import React from "react";
import { BsTrophyFill } from "react-icons/bs";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import FunFactCard from "./FunFactCard";
import { FaTelegram } from "react-icons/fa";

const FunFact = () => {
  return (
    <div className="px-0 grid grid-cols-2 md:grid-cols-4 pb-10">
      <FunFactCard icon={<BsTrophyFill />} des="وبینار ها و آموزش های کاربردی" />
      <FunFactCard icon={<SiAntdesign />} des="طراحی دقیق و حرفه ای" />
      <FunFactCard icon={<BiCodeAlt />} des="کد تمیز و قابل استفاده مجدد" />
      <FunFactCard icon={<FaTelegram />} des="فعالیت و پشتیبانی در تلگرام" />
    </div>
  );
};

export default FunFact;
