import React from "react";
import ResumeTitle from "./ResumeTitle";
import { MdWork } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <div className="w-full grid grid-cols-9 px-6">
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="طراحی وبسایت و سئو" icon={<MdWork />} />
        <ResumeCard
          badge="طراحی وبسایت"
          title="شرکت سپهر پروژه سپاهان"
          subTitle="طراحی وبسایت و سئو"
          des="ساخت و طراحی وبسایت بین المللی سه زبانه و انجام سئو برای شرکت سپهر پروژه سپاهان در مجارستان"
        />
        <ResumeCard
          badge="طراحی وبسایت"
          title="شرکت تاج خودرو"
          subTitle="طراحی وبسایت، سئو و تولید محتوا"
          des="ساخت و طراحی سایت رسمی شرکت تاج خودرو و انجام سئو وبسایت و همچنین تولید محتوا در شبکه های مجازی"
        />
        <ResumeCard
          badge="طراحی وبسایت"
          title="شرکت سامین توسعه اطلس"
          subTitle="طراحی وبسایت، سئو و و تولید محتوا"
          des="ساخت و طراحی وبسایت برای شرکت نصب و راه اندازی آسانسور سامین توسعه اطلس"
        />
        <ResumeCard
          badge="طراحی وبسایت"
          title="آموزشگاه زبان عارف"
          subTitle="طراحی وبسایت و سئو"
          des="طراحی وبسایت آموزشی و فروشگاهی دوره های زبان و امکانات آموزشی"
        />
        <ResumeCard
          badge="طراحی وبسایت"
          title="شرکت راهنما آسانسور"
          subTitle="طراحی وبسایت، سئو و تولید محتوا"
          des="طراحی سایت مرجع نقشه های آسانسور و تابلو برق و مقالات آموزشی آسانسور و پله برقی شرکت راهنما آسانسور"
        />
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
      </div>
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="افتخارات" icon={<GiGraduateCap />} />
        <ResumeCard
          badge="وبینار آموزشی"
          title="وبینار آموزشی تولید محتوا"
          subTitle="وبینار آموزشی"
          des="وبینار آموزشی تولید محتوا و قوانین سئو (مقدماتی) برگزار شده توسط مهندس کاتبی"
        />
        <ResumeCard
          badge="وبینار آموزشی"
          title="وبینار آموزش مقدماتی طراحی وبسایت"
          subTitle="وبینار آموزشی طراحی وب"
          des="وبینار آموزشی مقدماتی طراحی وبسایت به صورت فرانت اند با ری اکت جی اس توسط مهندس مجد و کاتبی"
        />
        <ResumeCard
          badge="وبینار آموزشی"
          title="وبینار آموزشی هوش مصنوعی"
          subTitle="وبینار آموزشی هوش مصنوعی و یادگیری ماشینها"
          des="وبینار آموزشی مقدماتی و درک مفاهیم هوش مصنوعی و یادگیری ماشینها با پایتون توسط مهندس مجد"
        />
        <ResumeCard
          badge="آموزش آنلاین"
          title="کلاس آموزشی طراحی برای کودکان"
          subTitle="کلاس آموزشی آنلاین برای کودکان"
          des="برگزاری کلاس آموزشی طراحی و مفاهیم آن برای کودکان و نوجوانان زیر نظارت آموزش و پرورش توسط مهندس کاتبی"
        />
        <ResumeCard
          badge="ثبت مقاله"
          title="ثبت مقاله بین المللی هوش مصنوعی"
          subTitle="انتشار مقاله انگلیسی زبان هوش مصنوعی"
          des="ثبت و انتشار مقاله هوش مصنوعی و یادگیری ماشینها توسط مهندس مجد"
        />
      </div>
    </div>
  );
};

export default Education;
