import React from "react";

const ResumeCard = ({ badge, title, subTitle, des }) => {
  return (
    <div className="w-full py-6 flex flex-col gap-2.5 border-b-[1px] border-b-zinc-800">
      <div className="w-28 text-center text-sm py-[1px] text-designColor border-[1px] border-designColor rounded-sm">
        {badge}
      </div>

      <div className="text-lg font-titleFont text-gray-200 font-medium">
        {title}
      </div>
      <p className="text-sm text-[#999] -mt-2">{subTitle}</p>
      <p className="text-base text-[#999] font-medium pl-10 text-right">
        {des}
      </p>
    </div>
  );
};

export default ResumeCard;
