import React, { useState } from "react";
import SidenavTitle from "./SidenavTitle";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import "./main.css";

const Sidenav = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const accordionItems = [
    {
      question: "پکیج های تولید محتواتون شامل چه مواردی میشود؟",
      content:
        "پکیج‌ها شامل نوشتن مقالات تخصصی در موضوعات مختلف، ایجاد محتوای وبلاگ برای بهبود سئو و جلب مخاطبان، تولید محتواهای مناسب برای شبکه‌های اجتماعی، محتواهای تبلیغاتی جهت تبلیغ محصولات و خدمات، توضیح محصولات و خدمات به شکل جامع و جذاب، ویرایش و بهینه‌سازی محتواهای موجود، تحقیق و توسعه محتواهای جدید، طراحی تصاویر و گرافیک‌ها به عنوان جزء‌های ارتباطی، و سایر خدمات مرتبط مثل ترجمه، بهینه‌سازی سئو، مدیریت محتوا در وبسایت و شبکه‌های اجتماعی و ... می‌شوند",
    },
    {
      question: "هزینه طراحی وبسایت چقدر است؟",
      content:
        "هزینه طراحی وبسایت بستگی به عواملی دارد که شامل اندازه و پیچیدگی وبسایت، نوع طراحی ، ویژگی‌ها و افزونه‌های مورد نیاز، طراحی ریسپانسیو (سازگار با دستگاه‌های مختلف)، و توسعه و سفارشی‌سازی است. طراحی سفارشی وبسایت معمولاً گران‌تر از استفاده از قالب‌های آماده است اما وبسایت ساخته شده منحصر به فرد خواهد بود. برای تخمین دقیق‌تر هزینه، میتوانید از قسمت تماس با ما فرم مربوطه را پر کنید و یا با استفاده از تماس تلفنی با کارشناسان ما در ارتباط باشید",
    },
    {
      question: "چطوری میتوانیم با شما قرارداد ببندیم؟",
      content:
        "قبل از هرگونه بسته شدن قرارداد به شما توصیه میکنیم با کارشناسان ما در ارتباط بوده و مشاوره کاملی درباره طراحی وبسایت و تولید محتوا برای ارتقا کسب و کار خود دریافت کنید. نمونه قرارداد پیش فرض شرکت جاویدان را شما میتوانید با کلیک بر روی دکمه دانلود قرارداد دریافت نموده و سپس مطالعه کنید. متن قرارداد باتوجه به نیاز  شما یا توافق طرفین قابل تغییر است. قرارداد دارای سه بخش مجزا  شامل طراحی وبسایت، تولید محتوا و سئو بوده که با توجه به نیازمندی مشتری هر یک از سه موارد فوق قابل حذف میباشد",
    },
    {
      question: "آیا طراحی وبسایت روی کسب و کار ما تاثیر دارد؟",
      content:
        "بله، طراحی وبسایت به طور قابل توجهی تاثیر بر کسب و کار شما دارد. وبسایت شما نقطه ورود اصلی مخاطبان و مشتریان به دنیای آنلاین شماست. یک وبسایت جذاب، کاربرپسند و حرفه‌ای باعث ایجاد اولین تاثیر مثبت بر مخاطبان می‌شود و به اعتبار و اعتماد به نام تجاری شما کمک می‌کند. همچنین، ویژگی‌ها و عملکرد بهینه وبسایت می‌توانند تجربه کاربری بهتری را فراهم کنند و به مشتریان امکاناتی مانند جستجوی آسان، فرآیند خرید راحت‌تر و اطلاعات دقیقتر ارائه دهند. همچنین، وبسایت مناسب بهینه‌سازی برای موتورهای جستجو و استراتژی‌های دیجیتال مارکتینگ می‌تواند به توسعه و رشد کسب و کار شما کمک کند. بنابراین، طراحی وبسایت با توجه به نیازها و اهداف کسب و کار بسیار مهم است و تاثیر زیادی بر روی موفقیت شما دارد",
    },
    {
      question: "چگونه میتوانیم در وبینار های شما شرکت کنم؟",
      content:
        "برای شرکت در وبینار های ما از قسمت تماس با ما فرم مربوطه را پر کرده و در قسمت توضیحات، درخواست خود را برای شرکت در وبینار های ما را ذکر کرده تا هنگام برگزاری وبینار بعدی مطلع شوید",
    },
    {
      question: "آیا شما پکیج های آموزشی هم دارید؟",
      content: "بله، پکیج های آموزشی ما در قسمت بلاگ جاویدان منتشر میشود",
    },
    {
      question: "چگونه میتوانم از شما مشاوره رایگان دریافت کنم؟",
      content:
        "برای دریافت مشاوره رایگان از قسمت تماس با ما میتوانید با شماره درج شده در آن قسمت تماس حاصل بفرمایید و یا از طریق فرم اطلاعات خود را پر کرده و منتظر تماس کارشناسان ما باشید",
    },
    {
      question: "آیا شما پشتیبانی هم ارائه میدهید؟",
      content:
        "برای تمامی پکیج های ارائه شده توسط تیم ما پشتیبانی رایگان محصول تا زمان تحویل پروژه ارائه خواهد شد، در صورت نیاز به پشتیبانی پس از پروژه با توجه به توافق طرفین در قرارداد ذکر خواهد شد",
    },
  ];

  const handleAccordionClick = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };

  return (
    <div className="px-7 py-4">
      <SidenavTitle title="سوالات" subTitle="متداول" />
      <ul dir="rtl">
        {accordionItems.map((item, index) => (
          <li
            key={index}
            className={`sidenavLi flex flex-col text-right relative ${
              openAccordion === index ? "active" : ""
            }`}
          >
            <div
              className="cursor-pointer flex items-center my-2"
              onClick={() => handleAccordionClick(index)}
            >
              {openAccordion === index ? (
                <FaAngleUp className="text-designColor" />
              ) : (
                <FaAngleDown />
              )}
              <span
                className={`mr-2 ${
                  openAccordion === index ? "text-designColor" : ""
                }`}
              >
                {item.question}
              </span>
            </div>

            <div
              className={`box-border text-justify rounded bg-designColor text-bodyColor transition-all duration-500 overflow-hidden ${
                openAccordion === index ? "expandedContent" : "collapsedContent"
              }`}
            >
              <span className="my-4 mx-8 leading-7 block">{item.content}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidenav;
