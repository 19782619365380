import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { BsCloudLightningFill, BsFacebook } from "react-icons/bs";
import { FaGithub, FaTelegram } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoIosMail } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import CV from "../../assets/contract.pdf";
import { bannerImg } from "../../assets/index";
import { IoFlashSharp } from "react-icons/io5";

const Left = () => {
  const [text] = useTypewriter({
    words: [
      "طراحی وبسایت",
      "هوش مصنوعی",
      "سئو",
      "طراحی اپلیکیشن",
      "طراحی رابط کاربری",
      "یادگیری ماشین",
    ],
    loop: true,
    typeSpeed: 30,
    deleteSpeed: 20,
    delaySpeed: 2000,
  });
  return (
    <div
      id="home"
      className="w-full lgl:w-5/12 h-full bg-bodyColor rounded-2xl shadow-testShwdow z-10"
    >
      <div className="w-full h-3/5">
        <img
          className="w-full h-full object-cover rounded-2xl"
          src={bannerImg}
          loading="priority"
          alt="bannerImage"
        />
      </div>
      <div className="w-full h-2/5 flex flex-col justify-between border-t-0 rounded-bl-xl rounded-br-xl">
        <div className="flex flex-col items-center gap-2 py-10">
          <h1 className="text-textColor text-4xl font-semibold">جاویدان</h1>
          <p className="text-base text-designColor tracking-wide flex flex-row gap-x-0.2 justify-center items-center mr-4">
            {text}
            <Cursor cursorBlinking="false" cursorStyle="┃" />
          </p>
          <div className="flex justify-center gap-2 mt-2">
            <a
              href="mailto:info@TheJavidan.ir"
              aria-label="Our GitHub"
              className="hover:text-designColor duration-300 cursor-pointer text-xl"
            >
              <FaGithub />
            </a>

            <a
              href="mailto:info@TheJavidan.ir"
              aria-label="Our YouTube"
              className="hover:text-designColor duration-300 cursor-pointer text-xl"
            >
              <FaTelegram />
            </a>
            <a
              href="mailto:info@TheJavidan.ir"
              aria-label="Our YouTube"
              className="hover:text-designColor duration-300 cursor-pointer text-xl"
            >
              <IoLogoWhatsapp />
            </a>
            <a
              href="mailto:info@TheJavidan.ir"
              aria-label="Our Facebook"
              className="hover:text-designColor duration-300 cursor-pointer text-xl"
            >
              <BsFacebook />
            </a>
            <a
              href="mailto:info@TheJavidan.ir"
              aria-label="Our Instagram"
              className="hover:text-designColor duration-300 cursor-pointer text-xl"
            >
              <RiInstagramFill />
            </a>

            <a
              href="mailto:info@TheJavidan.ir"
              aria-label="Mail Us"
              className="hover:text-designColor duration-300 cursor-pointer text-xl"
            >
              <IoIosMail />
            </a>
          </div>
        </div>
        <div className="flex h-14">
          <a
            href={CV}
            target="_blank"
            className="w-1/2 border-t-[1px] borderLeft border-t-zinc-800 text-sm tracking-wide uppercase  gap-2 hover:text-designColor duration-300"
            rel="noreferrer"
          >
            <button className="w-full h-full flex justify-center items-center gap-2">
              <BsCloudLightningFill />
              دانلود قرارداد
            </button>
          </a>
          <a
            href="tel:+989120052854"
            aria-label="Contact via Phone"
            class="w-1/2 border-t-[1px] border-t-zinc-800 text-sm tracking-wide uppercase flex justify-center items-center gap-2 hover:text-designColor duration-300"
          >
            <IoFlashSharp />
            تماس بگیرید
          </a>
        </div>
      </div>
    </div>
  );
};

export default Left;
