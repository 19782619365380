import React from "react";

const AboutMe = () => {
  return (
    <div className="flex flex-col md:flex-row pb-6">
      <div className="w-full md:w-1/2 text-zinc-400 px-6 border-l-[1px] border-l-zinc-800 flex items-center">
        <div className="py-6">
          <h2 className="font-semibold mb-1 text-right">
            شرکت جاویدان توسعه و فناوری
          </h2>
          <p className="text-base leading-6 text-right">
            شرکت جاویدان، یکی از پیشروان در زمینه برنامه‌نویسی و فناوری است. با
            تلاش مستمر و تکیه بر دانش فنی و تیمی مجرب، ما بهترین راهکارهایی را
            برای مشتریان عزیز ارائه می‌دهیم
          </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-6">
        <ul className="flex flex-col gap-1">
          <li className="aboutRightLi flex flex-row">
            <span className="aboutRightLiSpan">پکیج</span>
            کامل
          </li>
          <li className="aboutRightLi flex flex-row">
            <span className="aboutRightLiSpan">قرارداد</span>
            حضوری یا آنلاین
          </li>
          <li className="aboutRightLi flex flex-row">
            <span className="aboutRightLiSpan">پروژه</span>
            فریلنسر و دورکاری
          </li>
          <li className="aboutRightLi flex flex-row">
            <span className="aboutRightLiSpan">پشتیبانی</span>
            فعال شبانه روزی
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutMe;
