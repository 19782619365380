import React from "react";
import { FaAppStoreIos } from "react-icons/fa";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import ServicesCard from "./ServicesCard";
import { TiChartLineOutline } from "react-icons/ti";

const MyServices = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <ServicesCard
        icons={<BiCodeAlt />}
        title="طراحی وبسایت"
        subTitle="تیم ما با تمرکز بر طراحی زیبا، ساختاردهی منسجم و تجربه کاربری برتر، وبسایت‌های شخصی، تجاری و سازمانی را با رعایت استانداردهای بین‌المللی ایجاد می‌کند. با استفاده از ابزارهای پیشرفته و روش‌های نوین، ما وبسایتی منحصربه‌فرد و متناسب با نیازهای شما ایجاد خواهیم کرد"
      />
      <ServicesCard
        icons={<FaAppStoreIos />}
        title="اپلیکیشن موبایل"
        subTitle="تیم ما با استفاده از تکنولوژی‌های پیشرفته و طراحی واسط کاربری حرفه ای، اپلیکیشن‌هایی برای سیستم‌عامل‌های مختلف ایجاد می‌کند. از اپلیکیشن‌های تجاری و خدماتی تا اپلیکیشن‌های آموزشی و سرگرمی، هدف ما ایجاد اپلیکیشن‌هایی است که تجربه کاربری بی‌نظیری را به کاربران ارائه کنند"
      />
      <ServicesCard
        icons={<SiAntdesign />}
        title="تولید محتوا"
        subTitle="تیم ما با استفاده از تخصص و تجربه خود، محتواهای منحصربه‌فرد و به‌روز را برای شما ایجاد می‌کند. از مقالات و ویدئوهای آموزشی تا محتوای تبلیغاتی و اطلاعاتی، هدف ما ارائه محتواهایی است که به بازدیدکنندگان شما ارزش افزوده و اطلاعات مفید را ارائه کند"
      />
      <ServicesCard
        icons={<TiChartLineOutline />}
        title="سئو"
        subTitle="تیم ما با استفاده از تکنیک‌ها و روش‌های حرفه‌ای سئو، وبسایت شما را برای حضور برتر در نتایج جستجوی گوگل و سایر موتورهای جستجو بهینه می‌کند. از بهینه‌سازی کلمات کلیدی و بهبود ساختار وبسایت تا ایجاد لینک‌های استراتژیک و بهبود تجربه کاربری، هدف ما افزایش نمره و قابلیت دید در نتایج جستجو است"
      />
    </div>
  );
};

export default MyServices;
