import React from "react";
import Title from "../home/Title";
import {
  DiReact,
  DiGoogleAnalytics,
  DiHtml5Connectivity,
  DiRubyRough,
} from "react-icons/di";

const Package = () => {
  return (
    <section id="packages" className="w-full">
      <Title title="پکیج" subTitle="های ما" />
      <div className="flex flex-row justify-center flex-wrap relative">
        <span className="bg-zinc-800 absolute left-2/4 h-full w-px"></span>
        <ul className="py-4 px-20 flex flex-col items-center justify-center gap-2 border-b-[1px] border-b-zinc-800">
          <li className="flex flex-col items-center justify-center gap-4 text-textColor">
            <span className="text-designColor text-7xl">
              <DiReact />
            </span>
            <h2 className="text-xl text-center font-bold font-titleFont text-designColor -mt-4">
              طراحی وب
            </h2>
          </li>

          <li className="flex items-center gap-4 text-textColor">
            مدرن و ریسپانسیو
          </li>
          <li className="flex items-center gap-4 text-textColor">طراحی لوگو</li>
          <li className="flex items-center gap-4 text-textColor">
            خرید هاست و دامنه
          </li>
          <li className="flex items-center gap-4 text-textColor">
            پرداخت مرحله ای
          </li>
          <li className="flex items-center gap-4 text-textColor">
            تمامی امکانات فروشگاهی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی محتوای اولیه
          </li>
          <li className="flex items-center gap-4 text-textColor">
            تنظیمات امنیتی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            امکان طراحی اسلایدر
          </li>
          <li className="flex items-center gap-4 text-textColor">سئو اولیه</li>
          <li className="flex items-center gap-4 text-textColor">
            گالری محصولات
          </li>
          <li className="flex items-center gap-4 text-textColor">
            امکان ایجاد نظرسنجی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی فرم تماس
          </li>
          <li className="flex items-center gap-4 text-textColor">
            پنل اختصاصی ادمین
          </li>
          <li className="flex items-center gap-4 text-textColor">
            مشاوره تولید محتوا
          </li>
          <li className="flex items-center gap-4 text-textColor">فعال SSL</li>
          <a
            href="tel:+989120052854"
            aria-label="Contact via Phone"
            class="bg-designColor hover:bg-designColor/[0.7] my-2 text-bodyColor font-bold py-2 px-7 rounded-full transition duration-300 ease-in-out"
          >
            پشتیبانی
          </a>
        </ul>

        <ul className="py-4 px-20 flex flex-col items-center justify-center gap-2 border-b-[1px] border-b-zinc-800">
          <li className="flex flex-col items-center justify-center gap-4 text-textColor">
            <span className="text-designColor text-7xl">
              <DiGoogleAnalytics />
            </span>
            <h2 className="text-xl text-center font-bold font-titleFont text-designColor -mt-4">
              سئو
            </h2>
          </li>

          <li className="flex items-center gap-4 text-textColor">
            برسی و بهبود کدنویسی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            برسی و بهبود سئو لینک سازی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            برسی و بهبود محتوا و مقالات
          </li>
          <li className="flex items-center gap-4 text-textColor">
            برسی و بهبود کلمات کلیدی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            تحلیل و برسی سئو سایت
          </li>
          <li className="flex items-center gap-4 text-textColor">
            بهینه سازی عکس ها
          </li>
          <li className="flex items-center gap-4 text-textColor">
            نگهداری جایگاه وبسایت ماهانه
          </li>
          <li className="flex items-center gap-4 text-textColor">
            آنالیز وبسایت از نظر مشکلات
          </li>
          <li className="flex items-center gap-4 text-textColor">
            ارائه گزارش ماهانه
          </li>
          <li className="flex items-center gap-4 text-textColor">تحلیل رقبا</li>
          <li className="flex items-center gap-4 text-textColor">
            مانیتورینگ سئو
          </li>
          <li className="flex items-center gap-4 text-textColor">
            سئو داخلی و خارجی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            سئو ساختاری
          </li>
          <li className="flex items-center gap-4 text-textColor">
            ارائه سئو استاندارد
          </li>
          <li className="flex items-center gap-4 text-textColor">
            بهبود سرعت سایت
          </li>
          <a
            href="tel:+989120052854"
            aria-label="Contact via Phone"
            class="bg-designColor hover:bg-designColor/[0.7] my-2 text-bodyColor font-bold py-2 px-7 rounded-full transition duration-300 ease-in-out"
          >
            پشتیبانی
          </a>
        </ul>

        <ul className="py-4 px-10 flex flex-col items-center justify-center gap-2">
          <li className="flex flex-col items-center justify-center gap-4 text-textColor">
            <span className="text-designColor text-7xl">
              <DiHtml5Connectivity />
            </span>
            <h2 className="text-xl text-center font-bold font-titleFont text-designColor -mt-4">
              تولید محتوا
            </h2>
          </li>

          <li className="flex items-center gap-4 text-textColor">
            تولید محتوا حرفه ای
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی انواع بنر و اسلایدر
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی کاور پست ها در فضای مجازی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی ایکون پک اختصاصی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            اقدامات ری برند
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی کاراکتر شخصی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            ساخت ویدیو آموزشی و تبلیغاتی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            مقاله نویسی استاندارد
          </li>
          <li className="flex items-center gap-4 text-textColor">
            تولید محتوای سئو شده
          </li>
          <li className="flex items-center gap-4 text-textColor">
            تولید محتوای چند زبانه
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی لوازم شخصی و اداری با لوگو شما
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طرح های متنوع و خاص
          </li>
          <li className="flex items-center gap-4 text-textColor">
            فعالیت در اکثر شبکه های مجازی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی استند های تبلیغاتی و بیلبورد ها
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی و چاپ کارت ویزیت
          </li>
          <a
            href="tel:+989120052854"
            aria-label="Contact via Phone"
            class="bg-designColor hover:bg-designColor/[0.7] my-2 text-bodyColor font-bold py-2 px-7 rounded-full transition duration-300 ease-in-out"
          >
            پشتیبانی
          </a>
        </ul>

        <ul className="py-4 px-10 flex flex-col items-center justify-center gap-2">
          <li className="flex flex-col items-center justify-center gap-4 text-textColor">
            <span className="text-designColor text-7xl">
              <DiRubyRough />
            </span>
            <h2 className="text-xl text-center font-bold font-titleFont text-designColor -mt-4">
              پکیج سه گانه جاویدان
            </h2>
          </li>

          <li className="flex items-center gap-4 text-textColor">
            طراحی وب حرفه ای
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی انواع بنر و اسلایدر
          </li>
          <li className="flex items-center gap-4 text-textColor">
            تولید مقالات سئو شده
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی لوگو و کارت ویزیت
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی تم کسب و کار
          </li>
          <li className="flex items-center gap-4 text-textColor">
            راه اندازی شبکه های مجازی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            ساخت ویدیو تبلیغاتی
          </li>
          <li className="flex items-center gap-4 text-textColor">
            بکارگیری سئو استاندارد
          </li>
          <li className="flex items-center gap-4 text-textColor">
            خرید هاست و دامنه
          </li>
          <li className="flex items-center gap-4 text-textColor">
            تولید محتوای چند زبانه
          </li>
          <li className="flex items-center gap-4 text-textColor">
            طراحی لوازم شخصی و اداری با لوگو شما
          </li>
          <li className="flex items-center gap-4 text-textColor">فعال SSL</li>
          <li className="flex items-center gap-4 text-textColor">
            نگهداری جایگاه وبسایت ماهانه
          </li>
          <li className="flex items-center gap-4 text-textColor">
            ارائه گزارش ماهانه
          </li>
          <li className="flex items-center gap-4 text-textColor">
            توسعه اپلیکیشن موبایل
          </li>
          <a
            href="tel:+989120052854"
            aria-label="Contact via Phone"
            class="bg-designColor hover:bg-designColor/[0.7] my-2 text-bodyColor font-bold py-2 px-7 rounded-full transition duration-300 ease-in-out"
          >
            پشتیبانی
          </a>
        </ul>
      </div>
    </section>
  );
};

export default Package;
