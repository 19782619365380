import React, { useEffect, useState } from "react";
import { FaUser, FaEnvelope } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import { MdWork } from "react-icons/md";
import { SiGooglechat } from "react-icons/si";
import { AiFillHome } from "react-icons/ai";

const MobileMenu = () => {
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    // Smooth scrolling behavior
    const smoothScrollLinks = document.querySelectorAll('a[href^="#"]');

    smoothScrollLinks.forEach((link) => {
      link.addEventListener("click", function (e) {
        e.preventDefault();

        const targetId = this.getAttribute("href").substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          const offset =
            targetElement.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: offset,
            behavior: "smooth",
          });

          // Update the active section when clicking
          setActiveSection(targetId);
        }
      });
    });

    // Scroll event listener to update active section
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Define the sections and their offsets
      const sections = [
        "home",
        "about",
        "resume",
        "projects",
        "blog",
        "contact",
      ];
      const sectionOffsets = sections.map((section) => ({
        id: section,
        offset: document.getElementById(section).offsetTop,
      }));

      // Find the currently visible section
      let currentSection = "home";
      for (const section of sectionOffsets) {
        if (scrollPosition >= section.offset) {
          currentSection = section.id;
        } else {
          break;
        }
      }

      // Update the active section when scrolling
      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="fixed bottom-5 left-0 right-0 mx-auto bg-[#151515] shadow-[0_0px_20px_-15px_rgba(0,0,0,0.5)] shadow-designColor rounded-full w-10/12 p-4 lgl:hidden z-50">
      <nav className="flex flex-row">
        <a
          href="#home"
          aria-label="Navigate to Homepage"
          className={`w-full h-6 text-xl flex items-center justify-center cursor-pointer relative group ${
            activeSection === "home" ? "text-designColor" : ""
          }`}
        >
          <AiFillHome />
        </a>
        <a
          href="#about"
          aria-label="Navigate to About Us"
          className={`w-full h-6 text-xl flex items-center justify-center cursor-pointer relative group ${
            activeSection === "about" ? "text-designColor" : ""
          }`}
        >
          <FaUser />
        </a>
        <a
          href="#resume"
          aria-label="Navigate to Resume"
          className={`w-full h-6 text-xl flex items-center justify-center cursor-pointer relative group ${
            activeSection === "resume" ? "text-designColor" : ""
          }`}
        >
          <IoIosPaper />
        </a>
        <a
          href="#projects"
          aria-label="Navigate to Project"
          className={`w-full h-6 text-xl flex items-center justify-center cursor-pointer relative group ${
            activeSection === "projects" ? "text-designColor" : ""
          }`}
        >
          <MdWork />
        </a>
        <a
          href="#blog"
          aria-label="Navigate to Blog"
          className={`w-full h-6 text-xl flex items-center justify-center cursor-pointer relative group ${
            activeSection === "blog" ? "text-designColor" : ""
          }`}
        >
          <SiGooglechat />
        </a>
        <a
          href="#contact"
          aria-label="Navigate to Contact Us"
          className={`w-full h-6 text-xl flex items-center justify-center cursor-pointer relative group ${
            activeSection === "contact" ? "text-designColor " : ""
          }`}
        >
          <FaEnvelope />
        </a>
      </nav>
    </div>
  );
};

export default MobileMenu;
